import React from "react";

const ResourceListItem = ({ resource, onSelect, selected, className }) => {
  const onSelectClick = (value) => {
    //! bubble up selection to parent
    if (selected) return;
    if (onSelect) onSelect(value);
  };

  const selectedClass = selected ? "text-white bg-info" : "";
  return (
    <div
      className={`${selectedClass} ${className}`}
      onClick={() => onSelectClick(resource)}
    >
      <h4 value={resource.id}>
        {resource.item === undefined //if item is empty show just the decription
          ? `${resource.description}`
          : `${resource.item} - ${resource.description}`}
      </h4>
    </div>
  );
};

export default ResourceListItem;
