import React, { useState } from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header.jsx";

//services
import LargeButton from "../../shared/components/buttons/_LargeButton.jsx";
import TextInput from "../../shared/components/input/_TextInput.jsx";
import { useAddInspector } from "../../../hooks/useAddInspector.js";
import StatusMessage from "../../shared/components/status-message/_StatusMessage.jsx";
import { isNullOrWhitespace } from "../../../services/utils/stringUtils.js";

const AddInspector = ({ inspectionLine, onBack }) => {
  const [newInspectorDetails, setNewInspectorDetails] = useState({
    firstName: "",
    lastName: "",
    license: "",
  });
  const [addState, setAddState] = useState({
    loading: false,
    status: null,
    firstNameError: null,
    lastNameError: null,
    licenseError: null,
  });

  const { add } = useAddInspector();

  const isValid = () => {
    let isValid = true;
    if (isNullOrWhitespace(newInspectorDetails.firstName)) {
      setAddState((prev) => ({
        ...prev,
        firstNameError: "First Name cannot be empty!",
      }));
      isValid = false;
    } else {
      setAddState((prev) => ({
        ...prev,
        firstNameError: null,
      }));
    }

    if (isNullOrWhitespace(newInspectorDetails.lastName)) {
      setAddState((prev) => ({
        ...prev,
        lastNameError: "First Name cannot be empty!",
      }));
      isValid = false;
    } else {
      setAddState((prev) => ({
        ...prev,
        lastNameError: null,
      }));
    }

    if (isNullOrWhitespace(newInspectorDetails.license)) {
      setAddState((prev) => ({
        ...prev,
        licenseError: "First Name cannot be empty!",
      }));
      isValid = false;
    } else {
      setAddState((prev) => ({
        ...prev,
        licenseError: null,
      }));
    }

    return isValid;
  };

  console.log("inspector", addState);

  const onSubmitNewInspector = async () => {
    setAddState((prev) => ({ ...prev, loading: false, status: null }));

    if (!isValid()) return;

    setAddState({ loading: true, status: null });

    const status = await add(
      inspectionLine.inspectionLineId,
      inspectionLine.testingCompanyId,
      newInspectorDetails.firstName,
      newInspectorDetails.lastName,
      newInspectorDetails.license
    );

    setAddState({ loading: false, status: status });

    if (!status.hasError) onBack();
  };

  const renderMainContent = () => {
    return (
      <Stack gap={2}>
        <StatusMessage status={addState.status} />
        <TextInput
          forKey={"firstName"}
          label={"First Name*"}
          inputClassName={
            addState.firstNameError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.firstName}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              firstName: value,
            }));
          }}
        />
        <TextInput
          forKey={"lastName"}
          label={"Last Name*"}
          inputClassName={
            addState.lastNameError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.lastName}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              lastName: value,
            }));
          }}
        />
        <TextInput
          forKey={"license"}
          label={"License Number*"}
          inputType={"number"}
          inputClassName={
            addState.licenseError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.license}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              license: value,
            }));
          }}
        />
        <LargeButton
          disabled={addState.loading}
          loading={addState.loading}
          onClick={onSubmitNewInspector}
          title={"Add!"}
        />
      </Stack>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header title={"Add New Inspector"} onBack={onBack} />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default AddInspector;
