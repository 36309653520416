import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../shared/components/header/_Header";

//services
import * as deficiencySlice from "../../redux/features/deficiencies/deficiencyApiSlice";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import Label from "../shared/components/label/_Label";
import moment from "moment";
import { CAT_1_PERIODIC_RESULTS_LABELS } from "../../services/constants/inspConsts";

const InspectionDeficiencyHistory = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();
  const { inspectionLine, isLoading, isSuccess } = useInspectionLine();
  const defHistoryApi = deficiencySlice.useGetDeficienciesHistoryQuery(
    {
      deviceId: inspectionLine?.deviceId,
      inspectionId: inspectionLine?.inspectionId,
    },
    {
      skip: !inspectionLine || isLoading || !isSuccess,
    }
  );

  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const history = defHistoryApi.data?.result;

  const renderDeficiencies = () => {
    const def = history?.deficiencies;
    if (!def) {
      return <div>There are no deficiencies to display.</div>;
    }

    return def.map((d, i) => {
      const remedy = d?.remedy;
      const part = d?.elevatorPart;
      const violation = d?.violation;
      return (
        <div key={i} className="mt-2 align-self-start text-start">
          <Stack gap={2} direction="horizontal">
            <Label text={`Building Responsibility: `} />
            <Label
              className={"text-info"}
              text={part?.isBuildingResponsibility === true ? "Yes" : "No"}
            />
          </Stack>
          <Stack gap={2} direction="horizontal">
            <Label text={`Elevator Part: `} />
            <Label className={"text-info"} text={part?.description} />
          </Stack>
          <Stack gap={2} direction="horizontal">
            <Label text={`Violation:`} />
            <Label className={"text-info"} text={violation?.description} />
          </Stack>
          <Stack gap={2} direction="horizontal">
            <Label text={`Remedy: `} />
            <Label className={"text-info"} text={remedy?.description} />
          </Stack>
        </div>
      );
    });
  };

  const time =
    new Date(history?.inspectionDate).toLocaleDateString() +
    " - " +
    moment(history?.inspectionTime, "HH:mm").format("h:mm A");

  const getMainContent = () => {
    if (defHistoryApi.isError || !history) {
      return <div>Error</div>;
    } else if (defHistoryApi.isLoading) {
      return <div>Loading...</div>;
    } else if (defHistoryApi.isSuccess) {
      return (
        <Stack direction="vertical" gap={4} className="align-items-center">
          <Stack
            direction="horizontal"
            className="align-items-center justify-content-between"
          >
            <div className="text-center">
              <Label text={`Device`} />
              <Label text={inspectionLine?.deviceName} />
            </div>
            <div className="text-center">
              <Label text={`Insp. Line Id`} />
              <Label text={history?.inspectionLineId} />
            </div>
            <div className="text-center">
              <Label text={`Insp. Id`} />
              <Label text={history?.inspectionId} />
            </div>
            <div className="text-center">
              <Label text={`Insp. Date`} />
              <Label text={time} />
            </div>
          </Stack>
          <div className="text-center">
            <Label text={`Elevator Company`} />
            <Label text={history.elevatorCompany} />
          </div>
          <div className="text-center">
            <Label text={`Testing Company`} />
            <Label text={history.testingCompany} />
          </div>
          <div className="text-center">
            <Label text={`Comments`} />
            <Label text={history.comments ?? "N/A"} />
          </div>
          <Stack direction="vertical" gap={2}>
            {renderDeficiencies()}
          </Stack>
        </Stack>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Deficiency History"}
            subTitle={CAT_1_PERIODIC_RESULTS_LABELS[history?.cat1Results]}
            onBack={onBackClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>{getMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default InspectionDeficiencyHistory;
