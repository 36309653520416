import React, { useState } from "react";
import Label from "../../shared/components/label/_Label";
import {
  CAT5RESULTS,
  CAT_5_STATUS,
} from "../../../services/constants/inspConsts";
import ToggleButtonGroup from "../../shared/components/button-group/_ToggleButtonGroup";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";
import {
  cat5IndexToResult,
  cat5ResultToIndex,
  showCat5,
} from "../../../services/utils/inspectionType";
import { useUpdateInspDeviceCat5ResultsMutation } from "../../../redux/features/inspectionLine/inspectionApiSlice";
import { buildFromMutation } from "../../../services/utils/statusUtils";

const Cat5Results = ({
  inspectionLine,
  nbOfDeficiencies,
  disabled,
  onUpdateStatus,
}) => {
  const [cat5ConfirmSatisfactory, setCat5ConfirmSatisfactory] = useState(false);
  const [update, mutation] = useUpdateInspDeviceCat5ResultsMutation();
  const isLoading = mutation.isLoading;
  const typeId = inspectionLine?.inspectionTypeId;
  const cat5Visible = showCat5(typeId);

  const updateCat5 = async (res) => {
    const index = cat5ResultToIndex(res);
    if (inspectionLine.cat5Results === index) return;

    let payload = {
      inspectionLineId: inspectionLine?.inspectionLineId,
      cat5Index: index,
    };

    const response = await update(payload);
    const errorMsg = `Failed to update Cat 5 Results. Please try again.`;
    const successMsg = "Updated Cat 5 Results!";
    const status = buildFromMutation(response, successMsg, errorMsg);
    onUpdateStatus(status);
  };

  const onSelectCat5 = (res) => {
    if (res === CAT_5_STATUS.SATISFACTORY && nbOfDeficiencies > 0) {
      setCat5ConfirmSatisfactory(true);
      return;
    }

    updateCat5(res);
  };

  const confirmCat5Satisfactory = () => {
    setCat5ConfirmSatisfactory(false);
    updateCat5(CAT_5_STATUS.SATISFACTORY);
    console.log("confirm");
  };

  const isResultDisabled = (res) => {
    //if (res === CAT_5_STATUS.SATISFACTORY && nbOfDeficiencies > 0) return true;

    return false;
  };

  const renderCat5 = () => {
    if (!cat5Visible) return <></>;

    return (
      <>
        <ConfirmModal
          show={cat5ConfirmSatisfactory}
          title={"Warning"}
          text={"This will remove all defs for this device. Continue?"}
          onConfirm={confirmCat5Satisfactory}
          onClose={() => setCat5ConfirmSatisfactory(false)}
        />
        <p className="top-border"></p>
        <Label text={"CAT 5 Results"} className={"fw-light"} />
        <ToggleButtonGroup
          name={"cat5"}
          possibleResults={CAT5RESULTS}
          selectedResult={cat5IndexToResult(inspectionLine.cat5Results)}
          onSelect={onSelectCat5}
          disabled={disabled || isLoading}
          isResultDisabled={isResultDisabled}
        />
      </>
    );
  };

  return <>{renderCat5()}</>;
};

export default Cat5Results;
