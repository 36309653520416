import { buildFromQuery } from "../services/utils/statusUtils";
import { useGetResourcesQuery } from "../redux/features/resources/resourceApiSlice";
import { mapPartsArray, mapRemediesArray, mapViolationsArray } from "../services/utils/utilities";

export const useResources = () => {
  // get resources
  const resourcesApi = useGetResourcesQuery();

  const { elevatorParts, violations, remedies } = resourcesApi.isSuccess
    ? {
        elevatorParts: mapPartsArray(resourcesApi.data.elevatorParts),
        violations: mapViolationsArray(resourcesApi.data.violations),
        remedies: mapRemediesArray(resourcesApi.data.remedies),
      }
    : { elevatorParts: [], violations: [], remedies: [] };

  const failMsg = "Failed to fetch resources, try again.";
  const status = buildFromQuery(resourcesApi, undefined, failMsg);

  return {
    elevatorParts,
    violations,
    remedies,
    isLoading: resourcesApi.isLoading,
    isSuccess: !status.hasError,
    status,
  };
};
