import {
  CAT_1_PERIODIC_RESULTS_INDEXES,
  CAT_1_PERIODIC_STATUS,
  CAT_5_INDEXES,
  CAT_5_STATUS,
  CAT_TYPES,
} from "../constants/inspConsts";
import { MOBILE_STATUS, MOBILE_STATUS_BUTTON } from "../constants/inspConsts";

export const showCat1 = (inspectionTypeId) => {
  return (
    inspectionTypeId === CAT_TYPES.CAT1 ||
    inspectionTypeId === CAT_TYPES.CAT1_CAT5 ||
    showPeriodic(inspectionTypeId)
  );
};

export const showCat5 = (inspectionTypeId) =>
  inspectionTypeId === CAT_TYPES.CAT1_CAT5 ||
  inspectionTypeId === CAT_TYPES.CAT5;

export const showPeriodic = (inspectionTypeId) =>
  inspectionTypeId === CAT_TYPES.PERIODIC;

export const getCompleteButtonLabel = (mobileStatus) => {
  switch (mobileStatus) {
    case MOBILE_STATUS.TODO:
      return `Toggle to ${MOBILE_STATUS_BUTTON.COMPLETE}`;
    default:
      return `Toggle back to ${MOBILE_STATUS_BUTTON.TODO}`;
  }
};

export const getCompleteButtonVariant = (isCompleted, mobileStatus) => {
  if (isCompleted) return "secondary";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "warning";
  return "success";
};

export const cat1ResultToIndex = (res) => {
  switch (res) {
    case CAT_1_PERIODIC_STATUS.SATISFACTORY:
      return CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY;
    case CAT_1_PERIODIC_STATUS.UNSATISFACTORY:
      return CAT_1_PERIODIC_RESULTS_INDEXES.UNSATISFACTORY;
    default:
      return CAT_1_PERIODIC_RESULTS_INDEXES.NA;
  }
};

export const cat1IndexToResult = (inspectionResultValue) => {
  switch (inspectionResultValue) {
    case CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY:
      return CAT_1_PERIODIC_STATUS.SATISFACTORY;
    case CAT_1_PERIODIC_RESULTS_INDEXES.UNSATISFACTORY:
      return CAT_1_PERIODIC_STATUS.UNSATISFACTORY;
    default:
      return CAT_1_PERIODIC_STATUS.NA;
  }
};

export const cat5ResultToIndex = (inspectionResultValue) => {
  switch (inspectionResultValue) {
    case CAT_5_STATUS.SATISFACTORY:
      return CAT_5_INDEXES.SATISFACTORY;
    default:
      return CAT_5_INDEXES.NA;
  }
};

export const cat5IndexToResult = (res) => {
  switch (res) {
    case CAT_5_INDEXES.SATISFACTORY:
      return CAT_5_STATUS.SATISFACTORY;
    default:
      return CAT_5_STATUS.NA;
  }
};

export const validateLine = (line, nbOfDeficiencies) => {
  if (!line) return null;

  const satisfactory = isSatisfactory(line);
  if (satisfactory && nbOfDeficiencies > 0)
    return "No deficiencies are allowed when Cat result is Satisfactory";
  if (!satisfactory && nbOfDeficiencies <= 0)
    return "Atleast one deficiency is required when Cat result is Unsatisfactory";

  const isPeriodic = showPeriodic(line.inspectionTypeId);
  //console.log("validate", isPeriodic, line.elevatorContactId, line.inspectionTypeId);
  if (!isPeriodic && !line.elevatorContactId) return "Inspector is required";

  return null;
};

export const canCompleteLine = (line, nbOfDeficiencies) => {
  if (!line) return false;

  const satisfactory = isSatisfactory(line);
  console.log("canCompleteLine", satisfactory);
  if (satisfactory) return nbOfDeficiencies === 0;

  return nbOfDeficiencies > 0;
};

export const isSatisfactory = (line) => {
  if (!line) return false;

  const cat5Satisfactory = line.cat5Results === CAT_5_INDEXES.SATISFACTORY;
  const cat1Satisfactory =
    line.cat1Results === CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY;
  const isCat5 = showCat5(line.inspectionTypeId);
  const isCat1 = showCat1(line.inspectionTypeId);

  if (isCat1 && isCat5) {
    return cat1Satisfactory && cat5Satisfactory;
  }

  if (isCat5 && cat5Satisfactory) return true;
  if (isCat1 && cat1Satisfactory) return true;

  return false;
};

export const getMobileStatusLabel = (isCompleted, mobileStatus) => {
  if (isCompleted) return "Completed";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "Mobile Complete";
  return "ToDo";
};

export const getStatusBadgeColor = (isCompleted, mobileStatus) => {
  if (isCompleted) return "danger";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "warning";
  return "success";
};

export const getBorderColor = (isCompleted, mobileStatus) => {
  if (isCompleted) return "border-danger";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "border-warning";
  return "border-success";
};

export const getLockedStatus = (inspectionLine) => {
  if (!inspectionLine) return { isLocked: true, isLineCompleted: false };
  if (inspectionLine.lineCompleted)
    return { isLocked: true, isLineCompleted: true };
  if (inspectionLine.mobileStatus === MOBILE_STATUS.COMPLETE)
    return { isLocked: true, isLineCompleted: false };

  return { isLocked: false, isLineCompleted: false };
};
