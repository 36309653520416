import { Badge, Col, Container, Row, Stack } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./_SchedulerLine.css";
import Label from "../../../shared/components/label/_Label";
import IconButton from "../../../shared/components/buttons/_IconButton";
import moment from "moment/moment";
import { MOBILE_STATUS } from "../../../../services/constants/inspConsts";
import {
  getBorderColor,
  getMobileStatusLabel,
  getStatusBadgeColor,
} from "../../../../services/utils/inspectionType";

function SchedulerLine({ inspectionLine, onCardSelect }) {
  let {
    inspectionId,
    inspectionLineId,
    inspectionDate,
    inspectionTime,
    customerName,
    mobileStatus,
    isQce,
    inspLineComplete,
    inspectionType,
    buildingAddress,
    buildingCity,
    buildingState,
    buildingZip,
    borough,
    latitude,
    longitude,
    deviceName,
    corpName,
    lineCompleted: isCompleted,
  } = inspectionLine;

  const onCardClick = () => {
    if (onCardSelect) onCardSelect(inspectionLine);
  };

  return (
    <Card
      className={`border border-2 ${getBorderColor(isCompleted, mobileStatus)}`}
    >
      <Card.Body>
        <Container fluid={true}>
          <Row>
            <Col className="align-content-center">
              <Card.Title className="text-center">
                <Label
                  text={moment(inspectionTime, "HH:mm").format("h:mm A")}
                />
              </Card.Title>
            </Col>
            <Col className="align-content-center">
              <Card.Title>
                <Badge bg="secondary">
                  <Label className={"m-1"} text={inspectionType} />
                </Badge>
                <Card.Title className="text-center">
                  <Label text={`QCE`} />
                </Card.Title>
                <Card.Title className="text-center">
                  <Label text={`${isQce ? "Yes" : "No"}`} />
                </Card.Title>
              </Card.Title>
            </Col>
            <Col className="align-content-start">
              <IconButton
                title={"Open inspection"}
                iconClass={"ri-arrow-right-s-line"}
                onClick={onCardClick}
                size={"lg"}
              />
            </Col>
          </Row>
          <Row>
            <Col className="align-content-center">
              <Label
                className="text-center"
                text={`${buildingAddress}, ${buildingCity}`}
              />
              <Label
                className="text-center"
                text={`${buildingState}, ${borough}`}
              />
              <Label
                className="text-center"
                text={`AKA ${corpName ?? "N/A"}`}
              />
            </Col>
            <Col className="align-content-center">
              <Badge bg={getStatusBadgeColor(isCompleted, mobileStatus)}>
                <Label
                  className="m-1 text-center"
                  text={`${getMobileStatusLabel(isCompleted, mobileStatus)}`}
                />
              </Badge>
            </Col>
            <Col className="align-content-center">
              <Card.Title>
                <Label className="text-center" text={`Device ${deviceName}`} />
              </Card.Title>

              <Label className="text-center" text={`Insp. ${inspectionId}`} />
              <Label
                className="text-center"
                text={`Insp. Line ${inspectionLineId}`}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default SchedulerLine;
