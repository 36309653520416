import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Stack } from "react-bootstrap";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

// components
import MarkerWithInfoWindow from "./_MarkerWithInfoWindow";

// css
import "./map.css";

//services
import { useGetInspectionsByDayQuery } from "../../redux/features/inspectionLine/inspectionApiSlice";
import { DEFAULT_MAP_CENTER } from "../../services/constants/mapConsts";
import HeaderWithDaySelection from "../shared/components/header/_HeaderWithDaySelection";
import { useNavigate } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useInspections } from "../../hooks/useInspections";

const ScheduleMap = () => {
  const [selectedDayBuildingsMap, setSelectedDayBuildingsMap] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: DEFAULT_MAP_CENTER.LAT,
    lng: DEFAULT_MAP_CENTER.LNG,
    zoom: DEFAULT_MAP_CENTER.ZOOM,
  });
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const mapContainer = useRef(null);
  const navigate = useNavigate();
  const { inspections, isLoading, isSuccess, status } = useInspections();

  useEffect(() => {
    if (isSuccess && inspections && inspections.length > 0) {
      const result = Object.groupBy(inspections, (item) => item.buildingId);
      const buildings = [];
      const inspectionGroups = Object.values(result);

      for (let index = 0; index < inspectionGroups.length; index++) {
        const inspectionGroup = inspectionGroups[index];
        const first = inspectionGroup[0];
        const building = {
          buildingId: first.buildingId,
          inspectionId: first.inspectionId,
          borough: first.borough,
          buildingCity: first.buildingCity,
          buildingAddress: first.buildingAddress,
          inspectionLines: inspectionGroup,
          latitude: first.latitude,
          longitude: first.longitude,
        };

        buildings.push(building);
      }

      const firstBuilding = buildings[0];

      setSelectedDayBuildingsMap(buildings);
      setMapCenter((prev) => ({
        ...prev,
        lat: firstBuilding.latitude,
        lng: firstBuilding.longitude,
      }));
    }
  }, [inspections, isSuccess]);

  const onMarkerClickAssigned = (item) => {
    setSelectedBuilding(item);
    setMapCenter((prev) => ({
      ...prev,
      lat: item.latitude,
      lng: item.longitude,
    }));
  };

  const onMarkerModalClose = () => {
    setSelectedBuilding(null);
  };

  const onBuildingClick = (building) => {
    onMarkerClickAssigned(building);
    mapContainer.current?.scrollIntoView();
  };

  const isBuildingSelected = (building) => {
    return (
      selectedBuilding && selectedBuilding?.buildingId === building.buildingId
    );
  };

  const onLineClick = (line) => {
    // set lineId to route and navigate to inspection line
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        line.inspectionLineId
      )
    );
  };

  const renderBuildings = () => {
    if (selectedDayBuildingsMap.length === 0)
      return (
        <div className={`card m-0 border border-2`}>
          <div className="card-body">
            <h5 className="card-title card-link">Missing inspections</h5>
            <p className="card-text">No inspections were loaded for the day.</p>
          </div>
        </div>
      );

    return selectedDayBuildingsMap.map((building, i) => {
      const selectedBorder = isBuildingSelected(building)
        ? "border-primary"
        : "";
      return (
        <div
          key={i}
          className={`card m-0 border border-2 ${selectedBorder}`}
          onClick={() => onBuildingClick(building)}
        >
          <div className="card-body">
            <h5 className="card-title card-link">{`Inspection ${building.inspectionId}`}</h5>
            <p className="card-text">{`${building.borough}, ${building.buildingAddress}`}</p>
            <a className="link-info">Select to center on map.</a>
          </div>
        </div>
      );
    });
  };

  //Official docs here https://visgl.github.io/react-google-maps/docs
  const handleCameraChange = useCallback(({ detail }) => {
    //console.log("handleCameraChange", detail);
    setMapCenter(() => ({
      zoom: detail.zoom,
      lat: detail.center.lat,
      lng: detail.center.lng,
    }));
  });

  return (
    <>
      <Container className="container-fluid">
        <Row>
          <HeaderWithDaySelection schedulesCount={inspections?.length} />
        </Row>
        <Row className="map-row px-2">
          <div className="col-xl-10 border">
            <div className="google-maps" ref={mapContainer}>
              <APIProvider apiKey={"AIzaSyDTnuvPuXN7gcUoO0ebgvBGUosJiQURKhY"}>
                <Map
                  zoom={mapCenter.zoom}
                  onCameraChanged={handleCameraChange}
                  center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
                  mapId="SIERRA_MAP_ID"
                  reuseMaps={true}
                  colorScheme={"LIGHT"}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  {selectedDayBuildingsMap &&
                    selectedDayBuildingsMap.map((item, index) => {
                      return (
                        <MarkerWithInfoWindow
                          key={index}
                          onMarkerClick={() => onMarkerClickAssigned(item)}
                          onCloseInfoWindow={() => onMarkerModalClose(item)}
                          title={`Inspection ${item.inspectionId}`}
                          info={item.borough + "\n" + item.buildingAddress}
                          position={{ lat: item.latitude, lng: item.longitude }}
                          showInfoWindow={isBuildingSelected(item)}
                        />
                      );
                    })}
                </Map>
              </APIProvider>
            </div>
          </div>
          <div className="col-xl-2 buildings">
            <Stack direction="vertical" gap={3} className="justify-content-top">
              {renderBuildings()}
            </Stack>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ScheduleMap;
