import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API() + '/users/';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseController }),
    tagTypes: ['auth'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => 'GetCurrentUser',
        }),
    }),
});

export const {
    useGetCurrentUserQuery,
} = authApi;