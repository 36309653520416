import { useEffect, useState } from "react";
import { useGetInspectionsByDayQuery } from "../redux/features/inspectionLine/inspectionApiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { buildFromQuery } from "../services/utils/statusUtils";
import { getLockedStatus } from "../services/utils/inspectionType";

export const useInspectionLine = () => {
  const { inspectionLineId } = useParams();
  const { selectedDay } = useSelector((state) => state.profile);
  const [inspectionLine, setInspectionLine] = useState(null);
  const failMsg = "Failed to fetch inspection line, try again.";
  //const [retryCount, setRetryCount] = useState(0);

  const inspApi = useGetInspectionsByDayQuery(selectedDay?.date, {
    refetchOnMountOrArgChange: true,
  });
  const status = buildFromQuery(inspApi, undefined, failMsg);

  useEffect(() => {
    if (inspApi.data?.results) {
      const line = inspApi.data.results.find(
        (c) => `${c.inspectionLineId}` === `${inspectionLineId}`
      );
      setInspectionLine(line);
    }
  }, [inspApi.data, inspectionLine]);

  //TODO: Revisit this, try to force refetch if inspLine is missing. To reproduce just do a page refresh.
  // useEffect(() => {
  //   console.log("insApi", inspApi);
  //   if (!inspectionLine && retryCount < MAX_QUERY_RETRIES) {
  //     console.log("insApi refetching");
  //     inspApi.refetch();
  //   }
  // }, [inspectionLine]);

  //if (!inspectionLine) console.log("useInspectionLine - line is null", status);
  return {
    inspectionLine,
    isLoading: inspApi.isLoading,
    isSuccess: !status.hasError,
    lockedStatus: getLockedStatus(inspectionLine),
    status,
  };
};
