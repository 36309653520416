import React, { useState } from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header.jsx";

//services
import LargeButton from "../../shared/components/buttons/_LargeButton";
import ResourceList from "../../shared/components/resource-list/_ResourceList.jsx";
import StatusMessage from "../../shared/components/status-message/_StatusMessage.jsx";

const InspectorMain = ({ state, onBack, elevatorContacts, onUpdate, onAdd }) => {
  const [selected, setSelected] = useState(null);

  const onSelectClick = (value) => {
    //! bubble up selection to parent
    setSelected(value);
  };

  const onAddInspector = () => {
    //! bubble up to parent
    onAdd();
  };

  const onUpdateInspector = () => {
    if (selected) {
      onUpdate(selected);
      setSelected(null);
    }
  };

  const isSelected = (res) => {
    if (selected) {
      return res === selected;
    }

    return res.id === state.elevatorContactId;
  };

  const disabled = !selected || selected.id == state.elevatorContactId;

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Elevator Inspector"}
            subTitle={"Select inspector or add new one"}
            onBack={onBack}
            onAction={onAddInspector}
            actionClassName={"ri-add-fill"}
            actionText={"Add inspector"}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <StatusMessage status={elevatorContacts.status} />
          <ResourceList
            resources={elevatorContacts.contacts}
            onSelect={onSelectClick}
            isSelected={isSelected}
          />
          <Stack className="mt-2 mb-2">
            <LargeButton
              disabled={disabled}
              size={"sm"}
              title={"Update Inspector"}
              onClick={onUpdateInspector}
            />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectorMain;
