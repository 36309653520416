import React from "react";
import { Outlet } from "react-router-dom";
import "./ExternalLayout.css";

function ExternalLayout() {
  return (
    <div className="external-layout">
        <Outlet />
    </div>
  );
}

export default ExternalLayout;
