import { buildFromMutation } from "../services/utils/statusUtils";
import { useUpdateInspMobileStatusMutation } from "../redux/features/inspectionLine/inspectionApiSlice";

export const useUpdateMobileStatus = () => {
  const [updateMobileStatus] = useUpdateInspMobileStatusMutation();

  const update = async (inspectionLineId, mobileStatus) => {
    const payload = {
      inspectionLineId,
      mobileStatus,
    };

    const response = await updateMobileStatus(payload);
    const errorMessage = "Failed to update inspection status!";
    const successMessage = "Updated inspection status!";
    return buildFromMutation(response, successMessage, errorMessage);
  };

  return {
    update,
  };
};
