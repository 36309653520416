import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import "./InternalLayout.css";

function InternalLayout() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Outlet />;
}

export default InternalLayout;
