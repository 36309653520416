import { buildFromMutation } from "../services/utils/statusUtils";
import { useDeleteDeficiencyMutation } from "../redux/features/deficiencies/deficiencyApiSlice";

export const useDeficiencyMutations = () => {
  const [deleteDef] = useDeleteDeficiencyMutation();

  const deleteDeficiency = async (deficiencyId) => {
    const payload = {
      deficiencyId,
    };

    const response = await deleteDef(payload);
    const errorMessage = "Failed to update inspector!";
    const successMessage = "Updated inspector!";
    return buildFromMutation(response, successMessage, errorMessage);
  };

  return {
    deleteDeficiency,
  };
};
