import React from "react";
import { Button, Stack } from "react-bootstrap";
import { VERSION } from "../../services/constants/appConsts";
import { navigateToLogout } from "../../services/api/authApi";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApiSlice";

const Profile = () => {
  const user = useGetCurrentUserQuery();

  return (
    <Stack
      direction="vertical"
      className="justify-content-center"
      style={{ height: "90vh" }}
    >
      <h1>Profile</h1>

      <Stack gap={2} className="justify-content-center">
        <h2>SierraLive Mobile</h2>
        <h3>v: {VERSION}</h3>
        <h3>@Sierra Consulting Group, Inc.</h3>
        <h3>New york, New York</h3>
        <div className="d-flex justify-content-center">
          <Button
            variant="warning"
            size="lg"
            title="title"
            className={`fs-3 px-5`}
            onClick={navigateToLogout}
          >
            {"Logout"}
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default Profile;
