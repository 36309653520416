import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import DeficiencyDetailMain from "./components/_DeficiencyDetailMain";
import ResourceListPage from "../../shared/components/resource-list/_ResourceListPage";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./components/_DeficiencyDetailComponents";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../../routing/nav_routes";
import {
  useGetDeficienciesQuery,
  useUpsertDeficiencyMutation,
} from "../../../redux/features/deficiencies/deficiencyApiSlice";
import { useGetInspectionsByDayQuery } from "../../../redux/features/inspectionLine/inspectionApiSlice";
import { useResources } from "../../../hooks/useResources";

const InspectionDeficiencyDetails = () => {
  const navigate = useNavigate();
  const { inspectionLineId, deficiencyId } = useParams();
  const { elevatorParts, violations, remedies } = useResources();

  // get all deficiencies by inspectionLineId - call useEffect to get selected deficiency
  const { data, isSuccess } = useGetDeficienciesQuery({
    inspectionLineId: inspectionLineId,
  });
  const [upsertDeficiency, mutation] = useUpsertDeficiencyMutation();

  const { selectedDay } = useSelector((state) => state.profile);

  const lineSlice = useGetInspectionsByDayQuery(selectedDay?.date, {
    refetchOnMountOrArgChange: true,
  });

  const [state, setState] = useState({
    componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
  });

  const [selectedDeficiency, setSelectedDeficiency] = useState({
    part: {
      id: 0,
      description: "Select Part",
      item: "",
      location: "",
      isBuildingResponsibility: false,
    },
    violation: { id: 0, description: "Select Violation", item: "" },
    remedy: { id: 0, description: "Select Remedy", item: "" },
  });

  useEffect(() => {
    if (data && data.results && data.results.length > 0) {
      // get the selected deficiency from list of deficiencies
      const deficiency = data.results.find(
        (c) => c.deficiencyId == deficiencyId
      );
      if (deficiency) {
        setSelectedDeficiency({
          part: {
            id: deficiency.elevatorPart?.elevatorPartId,
            description: deficiency.elevatorPart?.description,
            item: deficiency.elevatorPart?.item,
            location: deficiency.elevatorPart?.location,
            isBuildingResponsibility:
              deficiency.elevatorPart?.isBuildingResponsibility,
          },
          violation: {
            id: deficiency.violation?.violationId,
            description: deficiency.violation?.description,
            item: deficiency.violation?.item,
          },
          remedy: {
            id: deficiency.remedy?.remedyId,
            description: deficiency.remedy?.description,
            item: deficiency.remedy?.item,
          },
        });
      } else {
        console.log("Deficiency not found - we are going to insert");
      }
    }
  }, [isSuccess]);

  const onBackClick = async () => {
    //! BACK TO LIST OF DEFICIENCIES FOR INSPECTION LINE
    // this is where we check if we should insert or update
    // on update only update if values are different - don't update if values are the same

    if (
      selectedDeficiency.part.id == 0 ||
      selectedDeficiency.violation.id == 0 ||
      selectedDeficiency.remedy.id == 0
    ) {
      // nothing was inserted or updated - we are missing a part, violation or remedy
      navigate(
        INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
          INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
          inspectionLineId
        )
      );
    } else {
      const line = lineSlice.data.results.find(
        (c) => c.inspectionLineId == inspectionLineId
      );

      let payload = {
        deficiencyId: deficiencyId,
        inspectionLineId: inspectionLineId,
        inspectionId: line.inspectionId,
        customerId: line.customerId,
        buildingId: line.buildingId,
        deviceId: line.deviceId,
        partId: selectedDeficiency.part.id,
        isBuildingResponsibility: selectedDeficiency.part
          .isBuildingResponsibility
          ? 1
          : 0,
        violationId: selectedDeficiency.violation.id,
        remedyId: selectedDeficiency.remedy.id,
      };

      let response = await upsertDeficiency(payload);
    }

    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onSelectPart = (part) => {
    // user selected a part - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, part }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectRemedy = (remedy) => {
    // user selected a remedy - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, remedy }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectViolation = (violation) => {
    // user selected a violation - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, violation }));

    // show the defficiency details component
    backToMainComponent();
  };

  const backToMainComponent = () => {
    setState((prev) => ({
      ...prev,
      componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    // should we show lists of resources? or the deficiency details component
    setState((prev) => ({ ...prev, componentToShow: comp }));
  };

  const render = () => {
    switch (state.componentToShow) {
      case DEFICIENCY_DETAILS_COMPONENTS.PART:
        return (
          <ResourceListPage
            title={"Elevator Part"}
            resources={elevatorParts}
            onSelect={onSelectPart}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.VIOLATION:
        return (
          <ResourceListPage
            title={"Violating Condition"}
            resources={violations}
            onSelect={onSelectViolation}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.REMEDY:
        return (
          <ResourceListPage
            title={"Remedy"}
            resources={remedies}
            onSelect={onSelectRemedy}
            onBack={backToMainComponent}
          />
        );
      default:
        return (
          <DeficiencyDetailMain
            state={selectedDeficiency}
            onShowComponent={onShowComponent}
            onBack={onBackClick}
          />
        );
    }
  };

  return <div>{render()}</div>;
};

export default InspectionDeficiencyDetails;
