import React, { useState } from "react";
import Label from "../../shared/components/label/_Label";
import {
  CAT1RESULTS,
  CAT_1_PERIODIC_STATUS,
} from "../../../services/constants/inspConsts";
import ToggleButtonGroup from "../../shared/components/button-group/_ToggleButtonGroup";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";
import { useUpdateInspDeviceCat1ResultsMutation } from "../../../redux/features/inspectionLine/inspectionApiSlice";
import {
  cat1IndexToResult,
  cat1ResultToIndex,
  showCat1,
  showPeriodic,
} from "../../../services/utils/inspectionType";
import { buildFromMutation } from "../../../services/utils/statusUtils";

const Cat1Results = ({
  inspectionLine,
  nbOfDeficiencies,
  disabled,
  onUpdateStatus,
}) => {
  const [cat1ConfirmSatisfactory, setCat1ConfirmSatisfactory] = useState(false);

  const [update, mutation] = useUpdateInspDeviceCat1ResultsMutation();
  const typeId = inspectionLine?.inspectionTypeId;
  const visible = showCat1(typeId);
  const isPeriodic = showPeriodic(typeId);
  const isLoading = mutation.isLoading;

  const updateCat1 = async (res) => {
    const index = cat1ResultToIndex(res);
    if (inspectionLine.cat1Results === index) return;

    const payload = {
      inspectionLineId: inspectionLine?.inspectionLineId,
      cat1Index: index,
    };

    const response = await update(payload);
    const errorMsg = `Failed to update Cat 1 Results. Please try again.`;
    const successMsg = "Updated Cat 1 Results!";
    const status = buildFromMutation(response, successMsg, errorMsg);
    onUpdateStatus(status);
  };

  const confirmCat1Satisfactory = () => {
    setCat1ConfirmSatisfactory(false);
    updateCat1(CAT_1_PERIODIC_STATUS.SATISFACTORY);
  };

  const onSelectCat1 = (res) => {
    if (res === CAT_1_PERIODIC_STATUS.SATISFACTORY && nbOfDeficiencies > 0) {
      setCat1ConfirmSatisfactory(true);
      return;
    }

    updateCat1(res);
  };

  const isResultDisabled = (res) => {
    // if (res === CAT_1_PERIODIC_STATUS.SATISFACTORY && nbOfDeficiencies > 0)
    //   return true;

    // if (res === CAT_1_PERIODIC_STATUS.UNSATISFACTORY && nbOfDeficiencies <= 0)
    //   return true;

    return false;
  };

  const renderCat1 = () => {
    if (!visible) return;
    const label = isPeriodic ? "Periodic Results" : "CAT 1 Results";
    const name = isPeriodic ? "periodic" : "cat1";

    return (
      <>
        <ConfirmModal
          show={cat1ConfirmSatisfactory}
          title={"Warning"}
          text={"This will remove all defs for this device. Continue?"}
          onConfirm={confirmCat1Satisfactory}
          onClose={() => setCat1ConfirmSatisfactory(false)}
        />
        <p className="top-border"></p>
        <Label text={label} className={"fw-light"} />
        <ToggleButtonGroup
          name={name}
          possibleResults={CAT1RESULTS}
          selectedResult={cat1IndexToResult(inspectionLine.cat1Results)}
          onSelect={onSelectCat1}
          disabled={disabled || isLoading}
          isResultDisabled={isResultDisabled}
        />
      </>
    );
  };

  return <>{renderCat1()}</>;
};

export default Cat1Results;
