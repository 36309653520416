//* Resource

import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import ResourceListItem from "./_ResourceListItem";
import { isNullOrWhitespace } from "../../../../services/utils/stringUtils";
import SearchTextInput from "../input/_SearchTextInput";

const ResourceList = ({ resources, onSelect, isSelected, className }) => {
  const [search, setSearch] = useState(null);

  const filter = () => {
    if (isNullOrWhitespace(search)) return resources;

    return resources.filter((res) => {
      if (isNullOrWhitespace(res.item ?? res.description)) return true;
      if (isNullOrWhitespace(search)) return true;
      return (
        res.item?.toLowerCase().includes(search.toLowerCase()) ||
        res.description?.toLowerCase().includes(search.toLowerCase())
      );
    });
  };

  const isResultSelected = (res) => {
    if (!isSelected) return false;
    return isSelected(res);
  };

  const buildLines = () => {
    if (!resources) return;

    const filtered = filter();
    return filtered.map((res) => {
      const selected = isResultSelected(res);
      return (
        <ResourceListItem
          key={res.id}
          resource={res}
          onSelect={onSelect}
          selected={selected}
          className={"border-top border-1 p-1"}
        />
      );
    });
  };

  const onSearchChange = (value) => {
    setSearch(() => value);
  };

  return (
    <Stack className={className}>
      <SearchTextInput
        initialValue={search}
        onValueChanged={onSearchChange}
        className={"mb-2"}
      />
      <Stack>{buildLines()}</Stack>
    </Stack>
  );
};

export default ResourceList;
