
// unauthenticated routes
export const EXTERNAL_ROUTES = {
    LOGOUT: '/logout',
    LOGIN: '/login',
    LOGIN_CALLBACK: '/login-callback',
};


export const INSPECTION_ROUTES_PARAMS = {
    INSPECTION_ID: ':inspectionId',
    INSPECTION_LINE_ID: ':inspectionLineId',
    DEFICIENCY_ID: ':deficiencyId'
}

// authenticated routes
export const INTERNAL_ROUTES = {
    ROOT: '/',
    HOME: '/home',
    SCHEDULER: '/scheduler',
    MAP: '/map',
    PROFILE: '/profile',
    INSPECTION: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}`,
    INSPECTION_DETAILS: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/details`,
    INSPECTION_DEFICIENCIES: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/deficiencies`,
    INSPECTION_COMMENTS: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/comments`,
    INSPECTION_DEFICIENCIES_DETAILS: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/deficiencies/${INSPECTION_ROUTES_PARAMS.DEFICIENCY_ID}`,
    INSPECTION_BRAKE_COMPLIANCE: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/brake-compliance`,
    INSPECTION_INSPECTOR: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/inspector`,
    INSPECTION_PHOTOS: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/images`,
    INSPECTION_DEFICIENCY_HISTORY: `/inspection/${INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID}/deficiency-history`,
};
