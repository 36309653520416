import React from "react";
import { Badge, Stack } from "react-bootstrap";
import StatusMessage from "../status-message/_StatusMessage";

const PageError = ({ show, status }) => {
  //if (status) console.log("PageError", status);
  if (!show) return <></>;

  return (
    <Stack
      style={{ height: "85vh" }}
      className="justify-content-center align-items-center"
      direction="vertical"
      gap={2}
    >
      <h1>
        <Badge bg="danger">Oops!</Badge> Something went wrong!
      </h1>
      <StatusMessage status={status} />
    </Stack>
  );
};

export default PageError;
