import React from "react";
import { Spinner, Stack } from "react-bootstrap";

const PageSpinner = ({ show }) => {
  if (!show) return <></>;

  return (
    <Stack
      style={{ height: "85vh" }}
      className="justify-content-center align-items-center"
    >
      <Spinner size="lg"></Spinner>
    </Stack>
  );
};

export default PageSpinner;
