import React, { useState, useEffect } from "react";

// components
import InspectionImagesMain from "./components/_InspectionImageMain";
import ResourceListPage from "../shared/components/resource-list/_ResourceListPage";
import { Col, Container, Row } from "react-bootstrap";

//services
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetInspectionLineImagesQuery,
  useAddInspectionLineImageMutation,
  useClearInspectionLineImageMutation,
} from "../../redux/features/inspectionLine/inspectionApiSlice";
import {
  buildEmptyInspectionImageItem,
  buildImageItemsArray,
} from "../../services/utils/inspectionImage";
import { STATUS_MESSAGES } from "../../services/constants/inspectionImageConsts";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useResources } from "../../hooks/useResources";
import {
  buildFromMutation,
  buildFromQuery,
} from "../../services/utils/statusUtils";
import PageError from "../shared/components/error/_PageError";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import Header from "../shared/components/header/_Header";
import StatusMessage from "../shared/components/status-message/_StatusMessage";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import ConfirmModal from "../shared/components/modal/_ConfirmModal";

const InspectionImages = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();
  const [showPartsResourceForPartItem, setShowPartsResourceForPartItem] =
    useState(null);
  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] =
    useState(false);
  const {
    inspectionLine,
    isLoading: insLoading,
    status: insStatus,
    lockedStatus,
  } = useInspectionLine();

  const [state, setState] = useState({
    inspectionLine: null,
    pending: [],
    items: [],
    mutationStatus: null,
    queryStatus: null,
  });

  const images = useGetInspectionLineImagesQuery(
    {
      inspectionLineId: inspectionLine?.inspectionLineId,
      inspectionId: inspectionLine?.inspectionId,
    },
    {
      skip: !inspectionLine,
    }
  );

  const [addImage] = useAddInspectionLineImageMutation();
  const [clearImage] = useClearInspectionLineImageMutation();
  const { elevatorParts } = useResources();

  useEffect(() => {
    if (images.isLoading) return;

    const status = buildFromQuery(images, null, STATUS_MESSAGES.FAILED_QUERY);
    if (status.hasError) {
      setState((prev) => ({
        ...prev,
        queryStatus: status,
      }));
    } else {
      const items = buildImageItemsArray(images.data, elevatorParts);
      setState((prev) => ({ ...prev, items: items, queryStatus: null }));
    }

    return () => {};
  }, [images]);

  const navigateBack = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onBackClick = () => {
    if (state.pending?.length > 0) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const onSelectImage = (imageItem, imageFile) => {
    if (lockedStatus.isLocked) return;
    updatePending({ ...imageItem, image: imageFile, pending: true });
    uploadImage(imageItem);
  };

  const onClearPartItem = (imageItem) => {
    if (lockedStatus.isLocked) return;
    clearImageItem(imageItem);
  };

  const onSelectPart = (imageItem, selectedPart) => {
    if (lockedStatus.isLocked) return;
    updatePending({ ...imageItem, part: selectedPart, pending: true });
    setShowPartsResourceForPartItem(null);
    uploadImage(imageItem);
  };

  const updatePending = (pendingItem) => {
    const indexOfPending = state.pending.findIndex(
      (p) => p.imageIndex === pendingItem.imageIndex
    );

    if (indexOfPending > -1) {
      state.pending[indexOfPending] = pendingItem;
    } else {
      state.pending.push(pendingItem);
    }
    setState((prev) => ({ ...prev, pending: [...state.pending] }));
  };

  const clearImageItem = async (imageItem) => {
    if (!inspectionLine) {
      return;
    }

    //Only clear if there is a URI present
    if (imageItem?.imageUri) {
      const line = inspectionLine;

      const resp = await clearImage({
        inspectionId: line.inspectionId,
        inspectionLineId: line.inspectionLineId,
        imageIndex: imageItem.imageIndex,
      });

      const status = buildFromMutation(
        resp,
        STATUS_MESSAGES.SUCCESS_CLEARING,
        STATUS_MESSAGES.FAILED_CLEARING
      );

      setState((prev) => ({
        ...prev,
        mutationStatus: status,
      }));
    }

    clearImageInState(imageItem);
  };

  const clearImageInState = (imageItem) => {
    const empty = buildEmptyInspectionImageItem(imageItem.imageIndex);
    state.items[imageItem.imageIndex] = empty;
    setState((prev) => ({ ...prev }));
  };

  const uploadImage = async (imageItem) => {
    if (!inspectionLine) {
      return;
    }

    const { image, part } = state.pending.find(
      (p) => p.imageIndex === imageItem.imageIndex
    );

    if (!image || !part) {
      setState((prev) => ({
        ...prev,
        mutationStatus: null,
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", image);

    const resp = await addImage({
      inspectionId: inspectionLine.inspectionId,
      inspectionLineId: inspectionLine.inspectionLineId,
      partId: part.id,
      imageIndex: imageItem.imageIndex,
      formData: formData,
    });

    const status = buildFromMutation(
      resp,
      STATUS_MESSAGES.SUCCESS_MUTATION,
      STATUS_MESSAGES.FAILED_MUTATION
    );

    if (status.hasError) {
      setState((prev) => ({
        ...prev,
        mutationStatus: status,
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      pending: prev.pending.filter(
        (p) => p.imageIndex !== imageItem.imageIndex
      ),
      mutationStatus: status,
    }));
  };

  const loading = insLoading || images.isLoading;

  console.log('render', state.items[0]);

  const renderMainContent = () => {
    if (loading) return <PageSpinner show={true} />;

    if (!inspectionLine || insStatus.hasError)
      return (
        <PageError
          show={true}
          subTitle={"Failed to fetch inspection line, try to refresh."}
        />
      );

    return (
      <InspectionImagesMain
        items={state.items}
        pending={state.pending}
        onSelectImage={onSelectImage}
        onClearPartItem={onClearPartItem}
        onSelectPart={(partItem) => setShowPartsResourceForPartItem(partItem)}
        isLocked={lockedStatus.isLocked}
      />
    );
  };

  if (showPartsResourceForPartItem)
    return (
      <ResourceListPage
        title={"Elevator Part"}
        subTitle={"Select a part"}
        resources={elevatorParts}
        onSelect={(part) => onSelectPart(showPartsResourceForPartItem, part)}
        onBack={() => setShowPartsResourceForPartItem(null)}
      />
    );

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Inspection Photos"}
            subTitle={"You can upload a maximum of 6 images"}
            onBack={onBackClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ConfirmModal
            title={"Confirm"}
            text={"You have pending image lines that will discarded, continue?"}
            show={showConfirmPendingAbandon}
            onClose={() => setShowConfirmPendingAbandon(false)}
            onConfirm={() => navigateBack()}
          />
          <StatusMessage status={state.queryStatus} />
          <StatusMessage status={state.mutationStatus} />
          <CompletedStatus lockedStatus={lockedStatus} />
          {renderMainContent()}
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionImages;
