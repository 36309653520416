import { useEffect, useMemo } from "react";
import { useGetCurrentUserQuery } from "../redux/features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_ROUTES } from "../routing/nav_routes";

export const useAuth = () => {
  const user = useGetCurrentUserQuery();
  const navigate = useNavigate();

  const isAuthenticated = user.isSuccess;
  const isPending = user.isLoading || user.isFetching;

  useEffect(() => {
    if (!isPending && user.isError) {
      navigate(EXTERNAL_ROUTES.LOGIN);
    }
  }, [user]);

  return { isAuthenticated, isPending };
};
