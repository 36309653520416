import { useLazyGetElevatorContactsQuery } from "../redux/features/resources/resourceApiSlice";
import { buildFromQuery } from "../services/utils/statusUtils";
import { mapElevatorContacts } from "../services/utils/utilities";

export const useElevatorContacts = () => {
  const [getElevatorContacts] = useLazyGetElevatorContactsQuery();

  const fetch = async (inspectionLine) => {
    const contactDetails = {
      inspectionLineId: inspectionLine.inspectionLineId,
      testingCompanyId: inspectionLine.testingCompanyId,
      elevatorContactId: inspectionLine.elevatorContactId,
      fullName: inspectionLine.elevatorContactFullName,
      license: inspectionLine.elevatorContactLicense,
    };

    const response = await getElevatorContacts(inspectionLine.testingCompanyId);
    const failMsg = "Failed to fetch elevator contact, try again.";
    const status = buildFromQuery(response, null, failMsg);
    const elevatorContacts = mapElevatorContacts(response.data) ?? [];

    return { contactDetails, elevatorContacts, status };
  };

  return {
    fetch,
  };
};
