import React from "react";
import { useSelector, useDispatch } from "react-redux";

// services
import * as profileSlice from "../../redux/features/profile/profileSlice";
import { useLazyGetResourcesQuery } from "../../redux/features/resources/resourceApiSlice";
import { VERSION } from "../../services/constants/appConsts";

const Home = () => {
  const dispatch = useDispatch();
  const [getResources] = useLazyGetResourcesQuery();

  const profile = useSelector((state) => state.profile);

  const onSetNameTest = () => {
    dispatch(profileSlice.setProfile());
  };

  const onClearName = () => {
    dispatch(profileSlice.clearProfile());
  };

  const onSetResources = async () => {
    //const { data, isError, isLoading, isSuccess } = await getResources();
    //const [trigger, items] = api.endpoints.getBuisnessAreas.useLazyQuery();
    let response = await getResources();
    console.log("response", response);
    console.log("error", response.isError);
    console.log("is loading", response.isLoading);
    console.log("is success", response.isSuccess);
    console.log(response.data);
    console.log("manually set resources");

    //dispatch(resourceSlice.setResources());
  };

  return (
    <div style={{ flex: 1, height: "100%", flexDirection: "column" }}>
      <h1>Home View</h1>

      <h1>Welcome!</h1>
      <br />
      <br />
      <h3>
        {profile.firstName}! <br />
        You have some work to do today!
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        We believe in you!!
      </h3>

      <br />
      <br />
      <br />
      <br />

      <h3>v: {VERSION}</h3>
    </div>
  );
};

export default Home;
