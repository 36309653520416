import { useUpdateCommentsMutation } from "../redux/features/inspectionLine/inspectionApiSlice";
import { buildFromMutation } from "../services/utils/statusUtils";

export const useCommentsUpdate = () => {
  const [updateComments, mutation] = useUpdateCommentsMutation();

  const update = async (inspectionLineId, comments) => {
    let payload = {
      inspectionLineId: inspectionLineId,
      comments: comments,
    };

    const response = await updateComments(payload);
    const failMsg = "Failed to update, try again!";
    const successMsg = "Successfully updated!";
    const status = buildFromMutation(response, successMsg, failMsg);

    return status;
  };

  return {
    isLoading: mutation.isLoading,
    update,
  };
};
