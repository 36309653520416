import { ENDPOINTS } from "../constants/appConsts";

const loginUrl = ENDPOINTS.API() + "/login";
const logoutUrl = ENDPOINTS.API() + "/logout";

export async function navigateToLogin() {
  console.log("Browser navigating to", loginUrl);
  window.location.href = loginUrl;
}

export function navigateToLogout() {
  window.location.href = logoutUrl;
}
