import React from "react";
import { Stack } from "react-bootstrap";
import LabelGroup from "../label/_LabelGroup";
import IconButton from "../buttons/_IconButton";

const ResourceItem = ({ description, action, onClick, disabled }) => {
  return (
    <Stack direction="horizontal">
      <LabelGroup
        description={description}
        text={action}
        containerClass={"flex-fill"}
      />
      <IconButton
        title={"Open inspection details"}
        size={"md"}
        iconClass={"ri-arrow-right-s-line"}
        onClick={onClick}
        disabled={disabled}
      />
    </Stack>
  );
};

export default ResourceItem;
