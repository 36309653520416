import { useGetInspectionsByDayQuery } from "../redux/features/inspectionLine/inspectionApiSlice";
import { useSelector } from "react-redux";
import { buildFromQuery } from "../services/utils/statusUtils";

export const useInspections = () => {
  const { selectedDay } = useSelector((state) => state.profile);
  const failMsg = "Failed to fetch inspection lines, try again.";
  const inspApi = useGetInspectionsByDayQuery(selectedDay?.date, {
    refetchOnMountOrArgChange: true,
  });
  const { data, isLoading } = inspApi;
  const status = buildFromQuery(inspApi, null, failMsg);

  return {
    inspections: data?.results,
    isLoading: isLoading,
    isSuccess: !status.hasError,
    status,
  };
};
