import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../header/_Header";
import ResourceList from "./_ResourceList";

const ResourceListPage = ({ title, subTitle, resources, onSelect, onBack }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Header title={title} subTitle={subTitle} onBack={onBack} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ResourceList resources={resources} onSelect={onSelect} />
        </Col>
      </Row>
    </Container>
  );
};

export default ResourceListPage;
