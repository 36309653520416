import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import * as dateHelper from "../../../../services/utils/utilities";
import IconButton from "../buttons/_IconButton";
import LargeButton from "../buttons/_LargeButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSelectedDay } from "../../../../redux/features/profile/profileSlice";
import moment from "moment";
import { getToday } from "../../../../services/utils/dateUtils";

const HeaderWithDaySelection = ({ schedulesCount }) => {
  const dispatch = useDispatch();
  const { selectedDay } = useSelector((state) => state.profile);
  const forDate = new Date(selectedDay.date);
  const monthName = dateHelper.getMonthName(forDate);
  const dayName = dateHelper.getDayName(forDate);
  const dayOfMonth = forDate.getDate();

  const onNavigateBack = () => {
    let newSelectedDay = {
      date: moment(new Date(selectedDay.date))
        .subtract(1, "days")
        .format("M-D-YYYY"),
      dayOfWeek: moment(new Date(selectedDay.date))
        .subtract(1, "days")
        .format("dddd"),
    };

    updateSelectedDay(newSelectedDay);
  };

  const onNavigateForward = () => {
    let newSelectedDay = {
      date: moment(new Date(selectedDay.date))
        .add(1, "days")
        .format("M-D-YYYY"),
      dayOfWeek: moment(new Date(selectedDay.date))
        .add(1, "days")
        .format("dddd"),
    };

    updateSelectedDay(newSelectedDay);
  };

  const updateSelectedDay = (paload) => {
    dispatch(setSelectedDay(paload));
  };

  const onBackClick = () => {
    onNavigateBack();
  };

  const onForwardClick = () => {
    onNavigateForward();
  };

  const onToToday = () => {
    updateSelectedDay(getToday());
  };

  const today = getToday();
  const todayDisabled = selectedDay.date === today.date;
  const backDisabled =
    moment(new Date(selectedDay.date))
      .subtract(1, "days")
      .diff(moment(new Date(today.date)), "days") <= -11;
  const forwardDisabled =
    moment(new Date(selectedDay.date))
      .add(1, "days")
      .diff(moment(new Date(today.date)), "days") >= 11;

  return (
    <div className="px-2 mb-1">
      <Row className="align-items-center justify-content-between">
        <Col className="col-auto me-auto">
          <IconButton
            title="Back"
            onClick={onBackClick}
            iconClass={"ri-arrow-left-s-line"}
            size={"lg"}
            disabled={backDisabled}
          />
        </Col>
        <Col>
          <Stack
            direction="vertical"
            gap={1}
            className="justify-content-center p-2"
          >
            <h3 className="m-0">
              {dayName}, {monthName} {dayOfMonth}
            </h3>
            <h3 className="m-0">{schedulesCount} total</h3>
            <div className="d-flex justify-content-center">
              <LargeButton
                size={"md"}
                title={"Today"}
                onClick={onToToday}
                fontSize={"fs-4"}
                disabled={todayDisabled}
              />
            </div>
          </Stack>
        </Col>
        <Col className="col-auto">
          <IconButton
            title="Forward"
            onClick={onForwardClick}
            iconClass={"ri-arrow-right-s-line"}
            size={"lg"}
            disabled={forwardDisabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default HeaderWithDaySelection;
