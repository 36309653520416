import { useParams } from "react-router-dom";
import { useGetDeficienciesQuery } from "../redux/features/deficiencies/deficiencyApiSlice";
import { buildFromQuery } from "../services/utils/statusUtils";

export const useDeficiencies = () => {
  const { inspectionLineId } = useParams();

  //TODO: Check is fetches correctly after first fetch. Probably should build a dynamic tag with the inspection line id.
  const defApi = useGetDeficienciesQuery(
    {
      inspectionLineId: inspectionLineId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  //TODO: Revisit this, try to force refetch if defs are missing. To reproduce just refresh the page.
  // useEffect(() => {
  //   if (defApi.data?.Error) {
  //     defApi.refetch();
  //   }
  // }, []);

  const failMsg = "Failed to fetch deficiencies, try again.";
  const status = buildFromQuery(defApi, undefined, failMsg);

  return {
    deficiencies: defApi.data?.results,
    isLoading: defApi.isLoading,
    isSuccess: !status.hasError,
    status,
  };
};
