import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useSelector } from "react-redux";

// components
import InspectorMain from "./components/_InspectorMain";

//services
import {
  INSPECTOR_COMPONENTS,
  INSPECTOR_CONST,
} from "./components/_InspectorConst";
//import ResourceList from "../shared/components/resource-list/_ResourceList";
import {
  useGetInspectionsByDayQuery,
  useUpdateInspectorMutation,
  useInsertInspectorMutation,
} from "../../redux/features/inspectionLine/inspectionApiSlice";
import * as resourceSlice from "../../redux/features/resources/resourceApiSlice";
import { mapElevatorContacts } from "../../services/utils/utilities";
import { buildFromMutation } from "../../services/utils/statusUtils";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useElevatorContacts } from "../../hooks/useElevatorContacts";
import AddInspector from "./components/_AddInspector";

//css

const Inspector = () => {
  const { inspectionLineId } = useParams();
  const navigate = useNavigate();
  const { inspectionLine, isLoading, isSuccess, status } = useInspectionLine();
  const { fetch } = useElevatorContacts();
  // update inspector in db
  const [updateInspector] = useUpdateInspectorMutation();

  const [contactDetails, setContactDetails] = useState({
    inspectionLineId: 0,
    elevatorContactId: 0,
    testingCompanyId: 0,

    firstName: "",
    lastName: "",
    license: "",
    status: null,
  });

  const [showAddInspector, setShowAddInspector] = useState(false);

  const [elevatorContacts, setElevatorContacts] = useState({
    contacts: [],
    status: null,
  });

  useEffect(() => {
    if (inspectionLine) {
      const asyncFetch = async () => {
        const { contactDetails, elevatorContacts, status } = await fetch(
          inspectionLine
        );

        setContactDetails(contactDetails);
        setElevatorContacts({ contacts: elevatorContacts, status: status });
      };

      asyncFetch();
    }
  }, [inspectionLine]);

  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onUpdateInspector = async (opt) => {
    // update mutation into redux
    // we need the id of the elevator contact
    // the description of the elevator contact has the name and license in one

    let payload = {
      inspectionLineId: contactDetails.inspectionLineId,
      elevatorContactId: opt.id,
    };

    const response = await updateInspector(payload);
    const status = buildFromMutation(response);

    if (status.hasError) {
      setContactDetails((prev) => ({ ...prev, status: status }));
      return;
    }

    setContactDetails((prev) => ({
      ...prev,
      elevatorContactId: opt.id,
      fullName: opt.fullName,
      license: opt.license,
    }));
  };

  const onAddInspector = async (payload) => {
    setShowAddInspector(true);
  };

  const render = () => {
    if (showAddInspector)
      return (
        <AddInspector
          inspectionLine={inspectionLine}
          onBack={onBackClick}
        />
      );

    return (
      <InspectorMain
        elevatorContacts={elevatorContacts}
        state={contactDetails}
        onUpdate={onUpdateInspector}
        onBack={onBackClick}
        onAdd={onAddInspector}
      />
    );
  };

  return <div>{render()}</div>;
};

export default Inspector;
