import React, { useEffect } from "react";
import { Stack, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../routing/nav_routes";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(INTERNAL_ROUTES.HOME);
  }, []);

  return (
    <Stack direction="horizontal" gap={3} className="justify-content-center">
      <h1>
        <Badge bg="danger">404</Badge> Page Not Found!
      </h1>
    </Stack>
  );
};

export default NotFound;
