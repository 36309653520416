import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API() + "/inspection/";

//! make api call to get resources

// no initial state for this service
// it will return and save to the api for us

export const inspectionApi = createApi({
  reducerPath: "inspections",
  baseQuery: fetchBaseQuery({ baseUrl: baseController }),
  tagTypes: ["Inspections"],
  endpoints: (builder) => ({
    getInspectionsByDay: builder.query({
      query: (selectedDate) => ({
        url: `GetInspectionByDay`,
        method: "GET",
        params: {
          // Add any additional query parameters here
          // For example:
          // status: params.status,
          date: selectedDate,
        },
      }),
      providesTags: ["Inspections"],
    }),
    updateBrakeCompliance: builder.mutation({
      query: (body) => ({
        url: "UpdateBrakeCompliance",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),

    updateInspector: builder.mutation({
      query: (body) => ({
        url: "UpdateInspector",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),

    insertInspector: builder.mutation({
      query: (body) => ({
        url: "InsertElevatorContact",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections", "ElevatorContacts"],
    }),
    updateComments: builder.mutation({
      query: (payload) => ({
        url: "UpdateComments",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inspections"],
    }),

    addInspectionLineImage: builder.mutation({
      query: (payload) => ({
        url: `AddInspectionImage?InspectionId=${payload.inspectionId}&InspectionLineId=${payload.inspectionLineId}&PartId=${payload.partId}&ImageIndex=${payload.imageIndex}`,
        method: "POST",
        body: payload.formData,
      }),
      invalidatesTags: ["Inspection-images"],
    }),
    getInspectionLineImages: builder.query({
      query: (params) => ({
        url: `GetInspectionImages`,
        method: "GET",
        params: {
          // Add any additional query parameters here
          // For example:
          // status: params.status,
          inspectionId: params.inspectionId,
          inspectionLineId: params.inspectionLineId,
        },
      }),
      transformResponse: (response, meta, arg) => response.results,
      providesTags: ["Inspection-images"],
    }),
    clearInspectionLineImage: builder.mutation({
      query: (payload) => ({
        url: `ClearInspectionImage?InspectionId=${payload.inspectionId}&InspectionLineId=${payload.inspectionLineId}&ImageIndex=${payload.imageIndex}`,
        method: "POST",
      }),
      invalidatesTags: ["Inspection-images"],
    }),
    updateInspDeviceCat5Results: builder.mutation({
      query: (payload) => ({
        url: `UpdateInspDeviceCat5Results`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inspections"],
    }),
    updateInspDeviceCat1Results: builder.mutation({
      query: (payload) => ({
        url: `UpdateInspDeviceCat1Results`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inspections"],
    }),
    updateInspMobileStatus: builder.mutation({
      query: (payload) => ({
        url: `UpdateInspectionMobileStatus`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inspections"],
    }),
  }),
});

//! use lazy to allow us to hit the api when we need to on demand
export const {
  useLazyGetInspectionsByDayQuery,
  useGetInspectionsByDayQuery,
  useUpdateInspectorMutation,
  useInsertInspectorMutation,
  useUpdateCommentsMutation,
  useUpdateBrakeComplianceMutation,
  useAddInspectionLineImageMutation,
  useGetInspectionLineImagesQuery,
  useClearInspectionLineImageMutation,
  useUpdateInspDeviceCat5ResultsMutation,
  useUpdateInspDeviceCat1ResultsMutation,
  useUpdateInspMobileStatusMutation,
} = inspectionApi;
