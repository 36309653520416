import React from "react";
const TextInput = ({
  forKey,
  label,
  initialValue,
  onValueChanged,
  className,
  inputType,
  inputClassName,
}) => {
  return (
    <div className={className}>
      <label htmlFor={forKey} className="form-label form-control-lg m-0">
        {label}
      </label>
      <input
        id={forKey}
        className={`form-control form-control-lg ${inputClassName}`}
        type={inputType ?? "text"}
        value={initialValue ?? ""}
        onChange={(e) => onValueChanged(e.target.value)}
      />
    </div>
  );
};

export default TextInput;
