import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApiSlice";
import { Button } from "react-bootstrap";

const LoginCallback = () => {
  const navigate = useNavigate();
  const user = useGetCurrentUserQuery();

  useEffect(() => {
    console.log(user);
    if (user.isSuccess) {
      navigate(INTERNAL_ROUTES.HOME);
    }
  }, [user, navigate]);

  const renderUpdateMessage = () => {
    if (user.isSuccess) return;

    if (user.isError) {
      console.log(user);
      if (user.error?.status === 404) {
        return (
          <h4 className={`fade text-danger alert alert-danger show m-0`}>
            {`Failed to login! User was not found in Sierra DB.`}
          </h4>
        );
      }

      return (
        <h4 className={`fade text-danger alert alert-danger show m-0`}>
          {`Failed to login! Try again.`}
        </h4>
      );
    }
  };

  return (
    <div className="app-login-callback">
      <h1>Login result</h1>
      {renderUpdateMessage()}
      <div className="d-flex justify-content-center">
        <Button
          variant="warning"
          className="fs-3 mt-5"
          onClick={() => navigate(EXTERNAL_ROUTES.LOGIN)}
        >
          Go to login
        </Button>
      </div>
    </div>
  );
};

export default LoginCallback;
