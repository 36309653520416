import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmModal({
  show,
  title,
  text,
  onConfirm,
  confirmBtnText,
  onClose,
  closeBtnText,
}) {
  const renderConfirm = () => {
    if (!onConfirm) return;

    return (
      <Button size="lg" variant="primary" onClick={onConfirm}>
        {confirmBtnText ?? "Confirm"}
      </Button>
    );
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="secondary" onClick={onClose}>
          {closeBtnText ?? "Close"}
        </Button>
        {renderConfirm()}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
