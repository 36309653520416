import { parseMutationError, parseQueryError } from "./requestErrorUtils";

export const danger = (msg, subText, statusCode) => {
  return {
    hasError: true,
    message: msg,
    type: "danger",
    subText: subText,
    statusCode: statusCode,
    show: (msg ?? subText) ? true : false,
  };
};

export const success = (msg, subText, statusCode) => {
  return {
    hasError: false,
    message: msg,
    type: "success",
    subText: subText,
    statusCode: statusCode,
    show: (msg ?? subText) ? true : false,
  };
};

export const buildFromQuery = (api, successMessage, errorMessage) => {
  const parsedError = parseQueryError(api);
  if (parsedError.hasError)
    return danger(errorMessage, parsedError.errorMsg, parsedError.statusCode);

  return success(successMessage);
};

export const buildFromMutation = (result, successMessage, errorMessage) => {
  //console.log("buildFromMutation", successMessage, errorMessage);
  const parsedError = parseMutationError(result);
  if (parsedError.hasError)
    return danger(errorMessage, parsedError.errorMsg, parsedError.statusCode);

  return success(successMessage);
};
