import React, { useEffect } from "react";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// components

// services
import { INTERNAL_ROUTES } from "../../routing/nav_routes";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApiSlice";
import { navigateToLogin } from "../../services/api/authApi";
import { ENDPOINTS, VERSION } from "../../services/constants/appConsts";

const Login = () => {
  const navigate = useNavigate();
  const user = useGetCurrentUserQuery();
  const loginUrl = ENDPOINTS.API() + "/login";

  console.log("login", loginUrl);

  useEffect(() => {
    if (user.isSuccess) {
      navigate(INTERNAL_ROUTES.HOME);
    }
  }, [user, navigate]);

  return (
    <div className="app-login">
      <h1>Login</h1>
      <Stack
        direction="vertical"
        gap={2}
        className="justify-content-center"
        style={{ height: "90vh" }}
      >
        <h2>SierraLive Mobile</h2>
        <h3>v: {VERSION}</h3>
        <h3>@Sierra Consulting Group, Inc.</h3>
        <h3>New york, New York</h3>
        <div className="d-flex justify-content-center">
          <Button
            variant="success"
            className="fs-3"
            onClick={() => navigateToLogin()}
          >
            Sign in with AD
          </Button>
        </div>
      </Stack>
    </div>
  );
};

export default Login;
