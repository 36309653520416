import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Col, Container, Row, Stack } from "react-bootstrap";
import moment from "moment/moment";

// components
import Header from "../shared/components/header/_Header";
import InspectionDetailsBar from "./components/inspectionDetailsBar/_InspectionDetailsBar";
import ResourceItem from "../shared/components/resource-item/_ResourceItem";
import LargeButton from "../shared/components/buttons/_LargeButton";
import Cat5Results from "./components/_Cat5Results";
import Cat1Results from "./components/_Cat1Results";

// services
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import StatusMessage from "../shared/components/status-message/_StatusMessage";
import {
  getCompleteButtonLabel,
  getCompleteButtonVariant,
  getMobileStatusLabel,
  getStatusBadgeColor,
  isSatisfactory,
  showPeriodic,
  validateLine,
} from "../../services/utils/inspectionType";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import PageError from "../shared/components/error/_PageError";
import { useDeficiencies } from "../../hooks/useDeficiencies";
import { useUpdateMobileStatus } from "../../hooks/useUpdateMobileStatus";
import { MOBILE_STATUS } from "../../services/constants/inspConsts";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import Label from "../shared/components/label/_Label";
import ErrorModal from "../shared/components/modal/_ErrorModal";

const Inspection = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();
  const {
    inspectionLine,
    isLoading: insLoading,
    status: insStatus,
    lockedStatus,
  } = useInspectionLine();
  const {
    deficiencies,
    isLoading: defLoading,
    isSuccess: defIsSuccess,
    status: defStatus,
  } = useDeficiencies();
  const [status, setStatus] = useState({});
  const [completeValidation, setCompleteValidation] = useState(null);
  const mobileStatusUpdate = useUpdateMobileStatus();
  const mobileStatus = inspectionLine?.mobileStatus;
  const loading = insLoading || defLoading;
  const isError = defStatus.hasError || insStatus.hasError;
  const errorStatus = defStatus.hasError ? defStatus : insStatus;
  const nbOfDeficiencies = deficiencies?.length;
  const typeId = inspectionLine?.inspectionTypeId;
  const isPeriodic = showPeriodic(typeId);
  const hideDefs = isSatisfactory(inspectionLine);
  const time =
    new Date(inspectionLine?.inspectionDate).toLocaleDateString() +
    " - " +
    moment(inspectionLine?.inspectionTime, "HH:mm").format("h:mm A");

  const onBackClick = () => {
    navigate(INTERNAL_ROUTES.SCHEDULER);
  };

  const onInspectionDetailsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onInspectorClick = () => {
    if (lockedStatus.isLocked) return;

    navigate(
      INTERNAL_ROUTES.INSPECTION_INSPECTOR.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onBrakeComplianceClick = () => {
    if (lockedStatus.isLocked) return;

    navigate(
      INTERNAL_ROUTES.INSPECTION_BRAKE_COMPLIANCE.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onDeficienciesClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewCommentsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_COMMENTS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewDeficiencyHistoryClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCY_HISTORY.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewPhotosClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_PHOTOS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onCompleteClick = async () => {
    if (lockedStatus.isLineCompleted) return;

    if (inspectionLine.mobileStatus === MOBILE_STATUS.TODO) {
      const error = validateLine(inspectionLine, nbOfDeficiencies);
      if (error) {
        setCompleteValidation(error);
        return;
      }
    }

    const newMobileStatus =
      inspectionLine.mobileStatus === MOBILE_STATUS.COMPLETE
        ? MOBILE_STATUS.TODO
        : MOBILE_STATUS.COMPLETE;

    const updateStatus = await mobileStatusUpdate.update(
      inspectionLine.inspectionLineId,
      newMobileStatus
    );
    setStatus(updateStatus);
  };

  const renderMainContent = () => {
    if (loading) return <PageSpinner show={true} />;
    if (isError) {
      //console.log(insStatus, defStatus);
      return <PageError show={true} status={errorStatus} />;
    }
    if (!inspectionLine)
      return (
        <PageError
          show={true}
          subTitle={"Failed to fetch inspection line, try to refresh."}
        />
      );

    return (
      <>
        <Stack
          direction="horizontal"
          className="align-items-center justify-content-between mb-2"
        >
          <ErrorModal
            title={"Can't complete"}
            error={completeValidation}
            onClose={() => setCompleteValidation(null)}
          />
          <div className="text-center">
            <Label text={`Device`} />
            <Label text={inspectionLine?.deviceName} />
          </div>
          <div className="text-center">
            <Label text={`Status`} />
            <Badge
              bg={getStatusBadgeColor(
                lockedStatus.isLineCompleted,
                mobileStatus
              )}
            >
              <Label
                className={"m-1 text-center"}
                text={getMobileStatusLabel(
                  lockedStatus.isLineCompleted,
                  mobileStatus
                )}
              />
            </Badge>
          </div>
          <div className="text-center">
            <Label text={`Insp. Id`} />
            <Label text={inspectionLine?.inspectionId} />
          </div>
          <div className="text-center">
            <Label text={`Insp. Date`} />
            <Label text={time} />
          </div>
        </Stack>

        <CompletedStatus lockedStatus={lockedStatus} />
        <StatusMessage status={status} />

        <p className="top-border"></p>

        <InspectionDetailsBar
          onInspectionDetailsClick={onInspectionDetailsClick}
        />

        <Stack
          direction="vertical"
          gap={3}
          className="mt-2 mb-3 justify-content-center"
        >
          <Cat5Results
            disabled={!defIsSuccess || lockedStatus.isLocked}
            nbOfDeficiencies={nbOfDeficiencies}
            inspectionLine={inspectionLine}
            onUpdateStatus={setStatus}
          />

          <Cat1Results
            disabled={!defIsSuccess || lockedStatus.isLocked}
            nbOfDeficiencies={nbOfDeficiencies}
            inspectionLine={inspectionLine}
            onUpdateStatus={setStatus}
          />

          <Stack direction="vertical" gap={2}>
            <p className="top-border"></p>

            <ResourceItem
              description={"BRAKE COMPLIANCE - MACHINE MANUFACTURER"}
              action={
                (inspectionLine?.brakeComplianceDisplay !== null
                  ? inspectionLine?.brakeComplianceDisplay
                  : "Select Status") +
                " - " +
                (inspectionLine?.machineManufacturer !== null
                  ? inspectionLine?.machineManufacturer
                  : "Select Manufacturer")
              }
              onClick={onBrakeComplianceClick}
              disabled={lockedStatus.isLocked}
            />
            {!isPeriodic && (
              <>
                <p className="top-border"></p>
                <ResourceItem
                  description={"ELEVATOR INSPECTOR"}
                  action={
                    inspectionLine?.elevatorContactFullName !== null
                      ? inspectionLine?.elevatorContactFullName +
                        " - " +
                        inspectionLine?.elevatorContactLicense
                      : "Select Inspector"
                  }
                  onClick={onInspectorClick}
                  disabled={lockedStatus.isLocked}
                />
              </>
            )}
            {!hideDefs && (
              <>
                <p className="top-border"></p>
                <ResourceItem
                  description={`DEFICIENCIES (${nbOfDeficiencies ?? "N/A"})`}
                  action={"View Device Deficiencies"}
                  onClick={onDeficienciesClick}
                />
              </>
            )}
            <p className="top-border"></p>
            <ResourceItem
              description={`INSPECTION DEVICE COMMENTS`}
              action={"View Comments"}
              onClick={onViewCommentsClick}
            />
            <p className="top-border"></p>
            <ResourceItem
              description={`DEFICIENCY HISTORY`}
              action={`Previous ${isPeriodic ? "Periodic" : "CAT 1"} Results`}
              onClick={onViewDeficiencyHistoryClick}
            />
            <p className="top-border"></p>
            <ResourceItem
              description={`INSPECTION PHOTOS`}
              action={"View/Take Photos"}
              onClick={onViewPhotosClick}
            />
          </Stack>
          <p className="top-border"></p>
          <LargeButton
            title={
              lockedStatus.isLineCompleted
                ? "Line completed, cannot update."
                : getCompleteButtonLabel(mobileStatus)
            }
            onClick={onCompleteClick}
            disabled={lockedStatus.isLineCompleted}
            variant={getCompleteButtonVariant(
              lockedStatus.isLineCompleted,
              mobileStatus
            )}
          />
        </Stack>
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Inspection Line"}
            subTitle={inspectionLineId}
            onBack={onBackClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default Inspection;
